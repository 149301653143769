import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/": [~9],
		"/auth/password-reset": [~39],
		"/auth/sign-in-sso": [~40],
		"/(app)/corp": [~10,[2]],
		"/(app)/inbox": [11,[2,3]],
		"/(app)/inbox/assigned": [12,[2,3]],
		"/(app)/inbox/reviewing": [13,[2,3]],
		"/(app)/kb": [14,[2,4]],
		"/(app)/kb/overview": [15,[2,4]],
		"/(app)/kb/qa": [~16,[2,4]],
		"/(app)/kb/qa/import-project/[projectId=uuid]": [18,[2,4]],
		"/(app)/kb/qa/import": [~17,[2,4]],
		"/(app)/kb/resources": [~19,[2,4]],
		"/(app)/kb/runs/custom/[id=uuid]": [~20,[2,4]],
		"/(app)/kb/runs/refresh": [~21,[2,4]],
		"/nux": [~41],
		"/(app)/projects": [22,[2,5]],
		"/(app)/projects/new": [~30,[2,5]],
		"/(app)/projects/[id=uuid]": [23,[2,5,6]],
		"/(app)/projects/[id=uuid]/delete": [~24,[2,5,6]],
		"/(app)/projects/[id=uuid]/download": [~25,[2,5,6]],
		"/(app)/projects/[id=uuid]/edit": [~26,[2,5,6]],
		"/(app)/projects/[id=uuid]/export": [~27,[2,5,6]],
		"/(app)/projects/[id=uuid]/extract": [28,[2,5,6]],
		"/(app)/projects/[id=uuid]/sections": [29,[2,5,6,7]],
		"/(app)/settings": [31,[2,8]],
		"/(app)/settings/autogeneration": [~32,[2,8]],
		"/(app)/settings/integrations": [33,[2,8]],
		"/(app)/settings/members": [34,[2,8]],
		"/(app)/settings/tags": [~35,[2,8]],
		"/(app)/settings/teams": [36,[2,8]],
		"/(app)/settings/user/notifications": [~37,[2,8]],
		"/(app)/settings/user/profile": [~38,[2,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';